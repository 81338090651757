/*
Design is meant to be responsive. Most elements that should be readable are based on rem.
Basic font size (1 rem in every element other than html) is 1 rem or 2.5 vmin, depending on
which one is larger. If a window is too narrow to correctly display horizontal layout it 
switches to vertical one (no javascript needed).

Positioning is achived using flexbox.
Each route is rendered in something like sub page with it's own scroll bar to support
transition animations.

There are 3 example transition animations. After they decide on one or propose changes other ones
can be deleted. Page transitions shouldn't take too long, currently it is 700 ms and I think it is
pushing it - JS

In IE11 next page arrow is displayed right after main content instead of at the bottom of the page.
I strongly suggest WON'T FIX. - JS
*/

/* #region Global */

@font-face {
  font-family:"URW Geometric W05";
  font-weight: 400;
  src:url("./fonts/5493384/d904254c-0a03-4733-9e31-93526ae18172.eot?#iefix");
  src:url("./fonts/5493384/d904254c-0a03-4733-9e31-93526ae18172.eot?#iefix") format("eot"),
    url("./fonts/5493384/ec13272f-d791-47aa-8eea-f28d0a24480a.woff2") format("woff2"),
    url("./fonts/5493384/5df82f3a-8710-4269-a645-3212fb184e8f.woff") format("woff"),
    url("./fonts/5493384/583a39ce-c652-4837-8c08-8ff81c2bbaf6.ttf") format("truetype");
}
@font-face {
  font-family:"URW Geometric W05";
  font-weight: 700;
  src:url("./fonts/5493404/50f79cb5-57ca-4373-9a07-6cf12462484d.eot?#iefix");
  src:url("./fonts/5493404/50f79cb5-57ca-4373-9a07-6cf12462484d.eot?#iefix") format("eot"),
      url("./fonts/5493404/b9a78a6c-6ed5-4f8f-90d8-78a064c20560.woff2") format("woff2"),
      url("./fonts/5493404/ee62384d-914d-4753-b864-244df9155fe1.woff") format("woff"),
      url("./fonts/5493404/db1c85f2-9fa8-4875-942e-1204281341e2.ttf") format("truetype");
}

html {
  font-size: 2.3vmin;
  font-weight: 400;
  color: #142a37;
  box-sizing: border-box;
  overflow: hidden;
}
body {
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}


* {
  font-family: 'URW Geometric W05';
}

h1 {
  font-size: 250%;
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }

/* #endregion */

/* #region Top bar */
.navigation {
  display: flex;
  flex-direction: row;
  height: 5rem;
  position: fixed;
  padding-top: 1.3rem;
  right: 0;
  left: 0;
  align-content: center;
  color: #142a37;
  z-index: 1000;
  flex-wrap: wrap;
  transition: color 1.2s ease-in-out, border-top-color 1.2s ease-in-out;
  background: inherit;
}

.contentDescription h2 {
  font-size: 165%;
}

.contentDescription h3 {
  margin-left: 0;
  width: 100%;
}
.contentDescription h4 {
  margin-left: 0;
}
.climateMarker {
  display: flex;
  text-align: center;
  flex-direction: row-reverse;
  padding-top: 1.2rem;
  flex: 1 0 auto;
  border-top: none;
}
.climateMarker label {
  background-color: rgb(38, 208, 124);
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  margin-right: 2rem;
}

.background-div {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: fixed;
}

.top-menu {
  display: flex;
  flex-wrap: wrap;
}

.navigation .top-menu-link a {
  flex: 0 0;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.6rem;
  display: block;
  text-align: left;
  text-decoration: none;
  white-space: nowrap; 
  color: #142a37;
  transition: color 1.2s ease-in-out;
}

.logo {
  flex: 0 0 calc(25vw - 1rem);
}

.logo img {
  padding-left: 4.3vmin;
  height: 2.5rem;
}

.dark .navigation a {
  color: #ffffff;
}

.dark.navigation{
  color: #ffffff;
}


.dark .burgerMenuIcon div {
  background-color: #ffffff;
}

.burgerMenuIcon div {
  background-color: #142a37;
}
/* #endregion */

/* #region Main content */

.spacer {
  flex: 1 0 0
}

.spacer .climateMarker{
  display: none;
}

.backgroundVideo{
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.top-border-div{
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  /*top: 0;
  height: 0.6rem;*/
  border-top-color: #F8475E;
  border-top-width: 0.6rem;
  border-top-style: solid;
  transition: border-top-color 1200ms;
  z-index: 1000;
}

@media (min-aspect-ratio: 16/9) {
  .backgroundVideo{
    width: 100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .backgroundVideo{
    height: 100%;
  }
}

.btnSendEmail{
  text-align: center;
  margin-top:auto;
  margin-bottom: auto;
  background: none;
  box-shadow: none;
  min-height: 50px;
  padding: 2%;
  border: 1px solid black;
  width: 100%;
  position: relative;
}

.contactFormText{
  height: 100%;
  width: 100%;
}

.contactFormButton{
  height: 100%;
  width: 100%;
  margin-bottom: 5px;
}

/*.captchaDiv{
  position: absolute !important;
  vertical-align: middle;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}*/

.main-page {
  flex: 3 0 60vmin;
  display: flex;
  flex-direction: column;
  height: auto;
  order: 2;
  opacity: 1;
  transition: opacity 300ms linear;
}

.main-page.disappear {
  opacity: 0;
}

.leftDiv {
  width: 45%;
  float: left;
}

.rightDiv {
  width: 45%;
  float: right;
}

.contact-form form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  width: 50vw;
}
.contact-form>form>p{
  display: block;
  flex: 0 0 50%;
  font-size: 0.75rem;
  padding-right: 3%;
}
.contact-form>form>button, .contact-form-recaptcha{
  display: block;
  flex: 0 0 47%;
  height: 80px;
  margin-right: 3%;
  background-color: white;
  border:black 1px solid;
  position: relative;
}
.contact-form-recaptcha{
  display: flex;
  justify-content: center;
  align-content: center;
}
.contact-form input,form textarea{
  flex: 0 0 100%;
  border: 0;
  margin-top: 5px;
  border-bottom: 1px solid black;
  width: 100%;
  resize: none;
}
.contact-form-full-width {
  flex: 0 0 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.contact-form .pretty input{
  border-bottom: none;
}

.contact-form input:focus,.contact-form textarea:focus {
  outline: none;
}
.contact-form>form>button:disabled{
  border-color: gray;
  color: gray;
  background-color:#eaeaea;
}

.index-image {
  position: absolute;
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.index-arrow {
  position: absolute;
  width: 10vh;
  bottom: 5vh;
  left: calc(50vw - 5vh);
}
.index-arrow img {
  width: 10vh;
}

.smallPrint {
  font-size: 12px;
}
.centered-content {
  padding-bottom: 5vh;

}
.link-main-site{
  font-size: 175%;
}
.form-errors {
  display: none;
}
.invalid{
  flex: 0 0 100%;
}
.invalid p{
  display: block;
  color: red;
  font-size: 75%;
  margin: 0;
  padding: 0;
}
.centered-content, .headerForMobile {
  flex: 0 0 auto;
  padding-right: 5vw;
  padding-left: 5vw;
}

.headerForMobile {
  display: none; 
}

.centered-content a div {
  transform: scale(1.5);
  margin-left: 0.1rem;
  padding-bottom: 0.3rem;
  padding-top: 0.5rem;
}
.content-block a {
  color:#142a37;
  text-decoration: underline;
}
.dark .content-block a {
  color:white;
  text-decoration: underline;
}
.dark .content-block .hr-feed a {
  text-decoration: none;
}

.climate-website #left-menu .menu a:first-child {
  color: #26D07C
}

.climate-website .burger-menu.climate-burger-menu a:first-child {
  color: #26D07C
}

.climate-website .pretty.p-default input:checked ~ .state label::after {
  background-color: #142a37 !important;
}

.publicationHeader {
  font-size: 1.17em;
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

input {
  border-radius: 0;
}

.legal-section{
  font-size: 75%;
}

.publicationRecord{
  border-bottom: solid rgba(0, 0, 0, .35);
  margin-bottom: 5vh;
  padding-bottom: 3vh;
}
.publicationDescription{
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.content-block {
  display: flex;
  position: relative;
  margin-top: 20vh;
  margin-left: 20vw;
  margin-right: 20vw;
  width: 60vw;
  min-height: calc(100vh - 20rem);
}

.contentHeader{
  margin-left: 5vw;
  margin-right: 5vw;
  border-bottom: solid;
  display: none;
}
.youtube-video{
  margin-left:calc(25vw - 280px);
}

.contentSpacer, .contentUpperSpacer{
  margin-left: 5vw;
  margin-right: 5vw;
  position: relative;
}

.climate-people {
  border-top: solid rgb(38, 208, 124);
  margin: 0rem 2rem 1rem -1rem;
}

.climate-people h4{
  margin-left: 0;
}

.blue-link {
  color: rgb(48, 126, 225);
}

.green-link {
  color: rgb(38, 208, 124);
}

.teal-link {
  color: rgb(59, 202, 218);
}

.purple-link {
  color: rgb(172, 79, 197);
}

.purple-top-border {
  border-top-color: rgb(172, 79, 197);
}

.blue-top-border {
  border-top-color: rgb(48, 126, 225);
}

.teal-top-border {
  border-top-color: rgb(59, 202, 218);
}

.green-top-border {
  border-top-color: rgb(38, 208, 124);
}

.red-top-border {
  border-top-color: rgb(248, 71, 94);
}

.peopleTable{
  display: flex;
  flex-direction: row;
}
.peopleColumn{
  flex: 1 0 0
}
/* #endregion */

/* #region Side menu */
.outer-menu {
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  flex-direction: column;
  justify-content: center;
  z-index: 50;
}
.right-menu {
  right: 0px;
  left: 80vw;
}
#left-menu .menu{
  align-items: stretch;
}
#left-menu .menu a{
  padding-right: 6vmin;
}

.menu {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.copyright{
  margin-left: 4vmin;
  flex: 0 0 auto;
  font-size: 50%;
  padding-bottom: 4vh;
}

.copyright> a{
  text-decoration: none;
  text-decoration-color: initial;
  color: inherit; 
  margin: initial;
  padding: initial !important;
}

.copyright-bottom-bar {
  display: block;
  margin-bottom: 2rem;
  font-size: 75%;
  align-self: flex-start;
  margin-top: auto;
}

.menu a, .empty-arrow, h4 {
  display: block;
  flex: 0 0 3vmin;
  margin-top: 1vmin;
  margin-bottom: 1vmin; 
  margin-left: 4vmin;
  /*width: calc(8vw + 6vmin);*/
  text-decoration: none;
}

.menu h4 {
  padding-left: 0.3rem; 
  margin-left: 4vmin;
}

.empty-arrow {
  opacity: 0;
}

.menu p {
  margin-left: 4vmin;
}

.menu a:visited {
  color: inherit
}

.menu a:link {
  color: inherit
}

.burger-menu {
  color: #ffffff;
  background-color: #142a37;
  width: 100%;
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: -5rem;
  font-size: 1rem;
  opacity: 0;
  z-index: 100;
  padding-top: 10rem;
  overflow: hidden;
  height: 0vh;
}
.burger-menu-content {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 12vw;
  padding-right: 12vw;
}
.burger-menu a {
  display: block;
  flex: 0 0 auto;
  padding-bottom: 0.3rem;
  color: white;
  text-decoration: none;
}
.burger-menu hr {
  width: 76vw;
}

.climate-burger-menu a {
  padding-bottom: 1rem;
}
.navigationBurgerMenu {
  display: none;
  float: right;
}

.burgerMenuIcon{
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 44px;
  height: 3px;
  transition: 0.4s;
}

.bar2 {
  margin: 15.5px 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: translate(0px, 18.5px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: translate(0px, -18.5px) rotate(45deg);
}


#left-menu a {
  padding-left: 0.3rem; 
  transition: color 1.2s ease-in-out, background-color 1.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  max-width: 20vw;
}

.right-menu .menu a {
  width: initial;
  display: table;
  width: fit-content;
  transition: color 1.2s ease-in-out;
}

.right-menu {
  order: 3;
}

.locationLink {
  color: rgb(248, 71, 94);
}

/* #endregion */

/* #region Next page arrow */
  .next-page {
    flex: 0 0 2.5rem; /*footer*/
    display: flex;
    justify-content: center;
  }

  .next-page svg{
    flex: 0 0;
  }

  .arrow-down-container {
    width: 5rem; /*footer*/
    margin-left: -0.8rem;
  }

/* #endregion */

/* #region Full page elements*/
.route {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%);
  z-index: 25;
  /*overflow-y: scroll;*/
}

.dark {
  color: #ffffff;
}

#root {
  overflow: hidden;
}
.overlay {
  height: calc(100vh - 6.5rem);
  margin-top: 6.5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 5;
}

/* #endregion */

/* #onetrust */
#ot-sdk-btn-floating.ot-floating-button {
  animation-name: mklOtFloatingBtnIntro !important;
}

#ot-sdk-btn-floating.ot-floating-button::before {
  border-right-color: #333!important;
  left: calc(0em - 5px) !important;
  transform: translate(-4.5px, -50%) rotate(180deg)!important;
}

#ot-sdk-btn-floating.ot-floating-button::after {
  right: calc(100% + 15px)!important;
  left: auto!important;
  transform: translate(10px, -50%);
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover {
  background: transparent!important;
}

@keyframes mklOtFloatingBtnIntro {
  0% {
      opacity: 0;
      right: -75px;
  }

  100% {
      opacity: 1;
      right: 1%;
  }
}

/* #region responsivenes */
@media (min-width: 20rem) {
  .vertical-list-with-triangles {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
  }

  .vertical-list-with-triangles li{
    display: block;
    flex: 1 0;
    padding-right: 0.8rem;
  }

  .vertical-list-with-triangles li::before{
    content: '';
    display: block;
    width: 0; /*li-triangle-size*/
    height: 0; /*li-triangle-size*/
    border-left: 1rem solid transparent; /*li-triangle-size*/
    border-right: 1rem solid transparent; /*li-triangle-size*/
    
    border-top: 1rem solid #47D496; /*li-triangle-size*/
    margin-bottom: 1.2rem
  }
}
/* Ensure font is minimum 1rem */
@media (max-width: 43.47rem), (max-height: 43.47rem){
  html{
    font-size: 1rem
  }
}
/* Portrait display 
@media (max-width: 45rem){
  .outer-menu {
    flex: 0 0 auto;
    order: 1;
  }
  .menu {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .menu a {
    flex: 1 0 auto;
    text-align: center;
    width: initial;
  }
  .right-menu .menu a{
    width: initial;
  }
  .content-block {
    flex-direction: column;
    height: 100%;
  }

  .main-page {
    flex: 1 0 auto;
    order: 2;
    min-height:initial;
    justify-content: space-between;
    align-self: stretch;
  }
  .spacer {
    display: none;
  }
  .centered-content {
    flex: 1 0 auto;
  }
  .right-menu .menu{
    border-top: 0.5vmin solid;
    border-left: initial;
  }
}
 #endregion */
 .disappear-main {
   opacity: 0;
   transition: none !important;
 }

 .appear-main {
   opacity: 1;
   transition: opacity 0.6s linear !important;
 }

@media only screen and (max-width: 800px) {
  .youtube-video{
    margin-left: initial;
  }
  .contact-form>form>button, .contact-form-recaptcha{
    flex: 0 0 97%;
    margin-top: 10px
  }
  .contact-form form{
    width: initial;
  }

  .contact-form > form > p {
    flex: 0 0 100%;
  }
  .displayBurgerMenu .navigation {
    color: #ffffff
  }
  .arrow-down-container {
    margin-left: calc(5vw - 1rem);
  }

  .displayBurgerMenu .navigation {
    color: #ffffff;
  }

  .displayBurgerMenu .navigation img {
    filter: brightness(0) saturate(100%) invert(100%);
  }

  .displayBurgerMenu .dark .navigation img {
    filter: initial;
  }

  .displayBurgerMenu .burgerMenuIcon div {
    background-color: #ffffff;
  }
  .navigation {
    position: relative;
    height: initial;
  }
  .logo,.navigationBurgerMenu {
    margin-top: 1rem;
  }
  .route {
    height: initial;
    overflow: auto;
    mask-image: initial;
  }
  .overlay {
    height: 100vh;
    margin-top: 0;
  }

  .content-block {
    margin-top: 0;
  }
  .navigationBurgerMenu{
    display: flex;
    flex-direction: row-reverse;
    flex: 1 0 auto;
    padding-right: 10vw;
  }

  .climateMarker{
    display: none;
  }

  .logo img {
    padding-left: 10vw;
  }
  .contentHeader{
    display: flex;
  }
  .headerForMobile{
    display: flex;
  }
  .spacer .climateMarker{
    display: flex;
    float: left;
    text-align: end;
    color: black;
    position: fixed;
    bottom: 0;
    margin: 2rem;
  }

  .displayBurgerMenu .burger-menu{
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.4s linear;
    min-height: calc(100vh + 5rem);
    height:auto;
    overflow: auto;
  }
  .displayBurgerMenu .route{
    overflow: hidden;
    height: calc(100vh - 5rem);
  }
  
  .right-menu, #left-menu, .top-menu {
    display: none;
  }
  .content-block {
    margin-left: 0;
    margin-right: 0;
    width: initial;
  }
  .centered-content {
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .leftDiv,.rightDiv {
    width: initial;
    float: initial;
  }
  html {
    overflow: initial;
  }
  body {
    overflow: initial;
  }
  #root {
    overflow: initial;
  }
  .peopleTable {
    display: block;
  }
  .peopleColumn {
    flex: none;
  }
  .climate-people {
    margin: initial;
  }
}